import React from 'react';

const Button = ({ onClick, className, children, disabled }) => (
  <button
    onClick={onClick}
    className={`p-2 text-white font-bold py-2 px-4 rounded transition-colors ${className}`}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
