import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import Button from './components/ui/Button';


// Function to get a cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

// Function to set a cookie
const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};


const mockCountries = [
  { "name": "Sierra Leone", "bordersShapeUrl": "../Sierra Leone.png" },
  { "name": "Switzerland", "bordersShapeUrl": "../Switzerland.png" },
  { "name": "Grenada", "bordersShapeUrl": "../Grenada.png" },
  { "name": "Ivory Coast", "bordersShapeUrl": "../Ivory Coast.png" },
  { "name": "Barbados", "bordersShapeUrl": "../Barbados.png" },
  { "name": "Hungary", "bordersShapeUrl": "../Hungary.png" },
  { "name": "Benin", "bordersShapeUrl": "../Benin.png" },
  { "name": "Italy", "bordersShapeUrl": "../Italy.png" },
  { "name": "Tunisia", "bordersShapeUrl": "../Tunisia.png" },
  { "name": "Saint Kitts and Nevis", "bordersShapeUrl": "../Saint Kitts and Nevis.png" },
  { "name": "Cape Verde", "bordersShapeUrl": "../Cape Verde.png" },
  { "name": "Indonesia", "bordersShapeUrl": "../Indonesia.png" },
  { "name": "Andorra", "bordersShapeUrl": "../Andorra.png" },
  { "name": "Uganda", "bordersShapeUrl": "../Uganda.png" },
  { "name": "Laos", "bordersShapeUrl": "../Laos.png" },
  { "name": "France", "bordersShapeUrl": "../France.png" },
  { "name": "South Africa", "bordersShapeUrl": "../South Africa.png" },
  { "name": "Burundi", "bordersShapeUrl": "../Burundi.png" },
  { "name": "Gabon", "bordersShapeUrl": "../Gabon.png" },
  { "name": "Mexico", "bordersShapeUrl": "../Mexico.png" },
  { "name": "Libya", "bordersShapeUrl": "../Libya.png" },
  { "name": "Yemen", "bordersShapeUrl": "../Yemen.png" },
  { "name": "China", "bordersShapeUrl": "../China.png" },
  { "name": "Macedonia", "bordersShapeUrl": "../Macedonia.png" },
  { "name": "Egypt", "bordersShapeUrl": "../Egypt.png" },
  { "name": "Uzbekistan", "bordersShapeUrl": "../Uzbekistan.png" },
  { "name": "Solomon Islands", "bordersShapeUrl": "../Solomon Islands.png" },
  { "name": "Bangladesh", "bordersShapeUrl": "../Bangladesh.png" },
  { "name": "Sri Lanka", "bordersShapeUrl": "../Sri Lanka.png" },
  { "name": "Senegal", "bordersShapeUrl": "../Senegal.png" },
  { "name": "Turkey", "bordersShapeUrl": "../Turkey.png" },
  { "name": "Singapore", "bordersShapeUrl": "../Singapore.png" },
  { "name": "Peru", "bordersShapeUrl": "../Peru.png" },
  { "name": "Zambia", "bordersShapeUrl": "../Zambia.png" },
  { "name": "United Kingdom", "bordersShapeUrl": "../United Kingdom.png" },
  { "name": "Afghanistan", "bordersShapeUrl": "../Afghanistan.png" },
  { "name": "Guinea-Bissau", "bordersShapeUrl": "../Guinea-Bissau.png" },
  { "name": "Niger", "bordersShapeUrl": "../Niger.png" },
  { "name": "Finland", "bordersShapeUrl": "../Finland.png" },
  { "name": "North Korea", "bordersShapeUrl": "../North Korea.png" },
  { "name": "Djibouti", "bordersShapeUrl": "../Djibouti.png" },
  { "name": "Azerbaijan", "bordersShapeUrl": "../Azerbaijan.png" },
  { "name": "Greece", "bordersShapeUrl": "../Greece.png" },
  { "name": "Colombia", "bordersShapeUrl": "../Colombia.png" },
  { "name": "Mauritius", "bordersShapeUrl": "../Mauritius.png" },
  { "name": "Algeria", "bordersShapeUrl": "../Algeria.png" },
  { "name": "Morocco", "bordersShapeUrl": "../Morocco.png" },
  { "name": "Croatia", "bordersShapeUrl": "../Croatia.png" },
  { "name": "Fiji", "bordersShapeUrl": "../Fiji.png" },
  { "name": "Sudan", "bordersShapeUrl": "../Sudan.png" },
  { "name": "Netherlands", "bordersShapeUrl": "../Netherlands.png" },
  { "name": "Georgia", "bordersShapeUrl": "../Georgia.png" },
  { "name": "Nepal", "bordersShapeUrl": "../Nepal.png" },
  { "name": "Liechtenstein", "bordersShapeUrl": "../Liechtenstein.png" },
  { "name": "Botswana", "bordersShapeUrl": "../Botswana.png" },
  { "name": "Monaco", "bordersShapeUrl": "../Monaco.png" },
  { "name": "Pakistan", "bordersShapeUrl": "../Pakistan.png" },
  { "name": "Dominican Republic", "bordersShapeUrl": "../Dominican Republic.png" },
  { "name": "Papua New Guinea", "bordersShapeUrl": "../Papua New Guinea.png" },
  { "name": "Lebanon", "bordersShapeUrl": "../Lebanon.png" },
  { "name": "India", "bordersShapeUrl": "../India.png" },
  { "name": "Madagascar", "bordersShapeUrl": "../Madagascar.png" },
  { "name": "Qatar", "bordersShapeUrl": "../Qatar.png" },
  { "name": "Eswatini", "bordersShapeUrl": "../Eswatini.png" },
  { "name": "Montenegro", "bordersShapeUrl": "../Montenegro.png" },
  { "name": "Syria", "bordersShapeUrl": "../Syria.png" },
  { "name": "Ukraine", "bordersShapeUrl": "../Ukraine.png" },
  { "name": "El Salvador", "bordersShapeUrl": "../El Salvador.png" },
  { "name": "Paraguay", "bordersShapeUrl": "../Paraguay.png" },
  { "name": "Bulgaria", "bordersShapeUrl": "../Bulgaria.png" },
  { "name": "United Arab Emirates", "bordersShapeUrl": "../United Arab Emirates.png" },
  { "name": "Namibia", "bordersShapeUrl": "../Namibia.png" },
  { "name": "Iraq", "bordersShapeUrl": "../Iraq.png" },
  { "name": "Cambodia", "bordersShapeUrl": "../Cambodia.png" },
  { "name": "Germany", "bordersShapeUrl": "../Germany.png" },
  { "name": "Kyrgyzstan", "bordersShapeUrl": "../Kyrgyzstan.png" },
  { "name": "Cuba", "bordersShapeUrl": "../Cuba.png" },
  { "name": "Sweden", "bordersShapeUrl": "../Sweden.png" },
  { "name": "Sao Tome and Principe", "bordersShapeUrl": "../Sao Tome and Principe.png" },
  { "name": "Malaysia", "bordersShapeUrl": "../Malaysia.png" },
  { "name": "Russia", "bordersShapeUrl": "../Russia.png" },
  { "name": "Malawi", "bordersShapeUrl": "../Malawi.png" },
  { "name": "Canada", "bordersShapeUrl": "../Canada.png" },
  { "name": "Cyprus", "bordersShapeUrl": "../Cyprus.png" },
  { "name": "Ethiopia", "bordersShapeUrl": "../Ethiopia.png" },
  { "name": "Bosnia and Herzegovina", "bordersShapeUrl": "../Bosnia and Herzegovina.png" },
  { "name": "Saudi Arabia", "bordersShapeUrl": "../Saudi Arabia.png" },
  { "name": "Oman", "bordersShapeUrl": "../Oman.png" },
  { "name": "Slovenia", "bordersShapeUrl": "../Slovenia.png" },
  { "name": "Spain", "bordersShapeUrl": "../Spain.png" },
  { "name": "Marshall Islands", "bordersShapeUrl": "../Marshall Islands.png" },
  { "name": "Lesotho", "bordersShapeUrl": "../Lesotho.png" },
  { "name": "San Marino", "bordersShapeUrl": "../San Marino.png" },
  { "name": "Argentina", "bordersShapeUrl": "../Argentina.png" },
  { "name": "Luxembourg", "bordersShapeUrl": "../Luxembourg.png" },
  { "name": "Iceland", "bordersShapeUrl": "../Iceland.png" },
  { "name": "Costa Rica", "bordersShapeUrl": "../Costa Rica.png" },
  { "name": "Dominica", "bordersShapeUrl": "../Dominica.png" },
  { "name": "Nauru", "bordersShapeUrl": "../Nauru.png" },
  { "name": "Haiti", "bordersShapeUrl": "../Haiti.png" },
  { "name": "Thailand", "bordersShapeUrl": "../Thailand.png" },
  { "name": "Australia", "bordersShapeUrl": "../Australia.png" },
  { "name": "Equatorial Guinea", "bordersShapeUrl": "../Equatorial Guinea.png" },
  { "name": "Honduras", "bordersShapeUrl": "../Honduras.png" },
  { "name": "Tuvalu", "bordersShapeUrl": "../Tuvalu.png" },
  { "name": "Latvia", "bordersShapeUrl": "../Latvia.png" },
  { "name": "Belarus", "bordersShapeUrl": "../Belarus.png" },
  { "name": "Saint Lucia", "bordersShapeUrl": "../Saint Lucia.png" },
  { "name": "Denmark", "bordersShapeUrl": "../Denmark.png" },
  { "name": "Philippines", "bordersShapeUrl": "../Philippines.png" },
  { "name": "Palau", "bordersShapeUrl": "../Palau.png" },
  { "name": "Bahrain", "bordersShapeUrl": "../Bahrain.png" },
  { "name": "Guinea", "bordersShapeUrl": "../Guinea.png" },
  { "name": "Cameroon", "bordersShapeUrl": "../Cameroon.png" },
  { "name": "Somalia", "bordersShapeUrl": "../Somalia.png" },
  { "name": "DR Congo", "bordersShapeUrl": "../DR Congo.png" },
  { "name": "Suriname", "bordersShapeUrl": "../Suriname.png" },
  { "name": "Togo", "bordersShapeUrl": "../Togo.png" },
  { "name": "Vanuatu", "bordersShapeUrl": "../Vanuatu.png" },
  { "name": "Czechia", "bordersShapeUrl": "../Czechia.png" },
  { "name": "Estonia", "bordersShapeUrl": "../Estonia.png" },
  { "name": "Rwanda", "bordersShapeUrl": "../Rwanda.png" },
  { "name": "Kenya", "bordersShapeUrl": "../Kenya.png" },
  { "name": "Guyana", "bordersShapeUrl": "../Guyana.png" },
  { "name": "Trinidad and Tobago", "bordersShapeUrl": "../Trinidad and Tobago.png" },
  { "name": "Romania", "bordersShapeUrl": "../Romania.png" },
  { "name": "Uruguay", "bordersShapeUrl": "../Uruguay.png" },
  { "name": "Vietnam", "bordersShapeUrl": "../Vietnam.png" },
  { "name": "Timor-Leste", "bordersShapeUrl": "../Timor-Leste.png" },
  { "name": "Antigua and Barbuda", "bordersShapeUrl": "../Antigua and Barbuda.png" },
  { "name": "Austria", "bordersShapeUrl": "../Austria.png" },
  { "name": "Vatican City", "bordersShapeUrl": "../Vatican City.png" },
  { "name": "Panama", "bordersShapeUrl": "../Panama.png" },
  { "name": "Mozambique", "bordersShapeUrl": "../Mozambique.png" },
  { "name": "Turkmenistan", "bordersShapeUrl": "../Turkmenistan.png" },
  { "name": "Norway", "bordersShapeUrl": "../Norway.png" },
  { "name": "Ireland", "bordersShapeUrl": "../Ireland.png" },
  { "name": "Micronesia", "bordersShapeUrl": "../Micronesia.png" },
  { "name": "Eritrea", "bordersShapeUrl": "../Eritrea.png" },
  { "name": "Burkina Faso", "bordersShapeUrl": "../Burkina Faso.png" },
  { "name": "Central African Republic", "bordersShapeUrl": "../Central African Republic.png" },
  { "name": "Jordan", "bordersShapeUrl": "../Jordan.png" },
  { "name": "South Korea", "bordersShapeUrl": "../South Korea.png" },
  { "name": "Tanzania", "bordersShapeUrl": "../Tanzania.png" },
  { "name": "Slovakia", "bordersShapeUrl": "../Slovakia.png" },
  { "name": "Lithuania", "bordersShapeUrl": "../Lithuania.png" },
  { "name": "Mauritania", "bordersShapeUrl": "../Mauritania.png" },
  { "name": "Moldova", "bordersShapeUrl": "../Moldova.png" },
  { "name": "Kazakhstan", "bordersShapeUrl": "../Kazakhstan.png" },
  { "name": "Angola", "bordersShapeUrl": "../Angola.png" },
  { "name": "Samoa", "bordersShapeUrl": "../Samoa.png" },
  { "name": "Armenia", "bordersShapeUrl": "../Armenia.png" },
  { "name": "Mali", "bordersShapeUrl": "../Mali.png" },
  { "name": "Chile", "bordersShapeUrl": "../Chile.png" },
  { "name": "Bolivia", "bordersShapeUrl": "../Bolivia.png" },
  { "name": "Japan", "bordersShapeUrl": "../Japan.png" },
  { "name": "Seychelles", "bordersShapeUrl": "../Seychelles.png" },
  { "name": "Saint Vincent and the Grenadines", "bordersShapeUrl": "../Saint Vincent and the Grenadines.png" },
  { "name": "United States", "bordersShapeUrl": "../United States.png" },
  { "name": "Tajikistan", "bordersShapeUrl": "../Tajikistan.png" },
  { "name": "Ecuador", "bordersShapeUrl": "../Ecuador.png" },
  { "name": "Guatemala", "bordersShapeUrl": "../Guatemala.png" },
  { "name": "Nigeria", "bordersShapeUrl": "../Nigeria.png" },
  { "name": "Gambia", "bordersShapeUrl": "../Gambia.png" },
  { "name": "Malta", "bordersShapeUrl": "../Malta.png" },
  { "name": "Maldives", "bordersShapeUrl": "../Maldives.png" },
  { "name": "Kuwait", "bordersShapeUrl": "../Kuwait.png" },
  { "name": "Bahamas", "bordersShapeUrl": "../Bahamas.png" },
  { "name": "Albania", "bordersShapeUrl": "../Albania.png" },
  { "name": "Iran", "bordersShapeUrl": "../Iran.png" },
  { "name": "South Sudan", "bordersShapeUrl": "../South Sudan.png" },
  { "name": "Belize", "bordersShapeUrl": "../Belize.png" },
  { "name": "Serbia", "bordersShapeUrl": "../Serbia.png" },
  { "name": "Brazil", "bordersShapeUrl": "../Brazil.png" },
  { "name": "Venezuela", "bordersShapeUrl": "../Venezuela.png" },
  { "name": "Bhutan", "bordersShapeUrl": "../Bhutan.png" },
  { "name": "Myanmar", "bordersShapeUrl": "../Myanmar.png" },
  { "name": "Poland", "bordersShapeUrl": "../Poland.png" },
  { "name": "Jamaica", "bordersShapeUrl": "../Jamaica.png" },
  { "name": "Liberia", "bordersShapeUrl": "../Liberia.png" },
  { "name": "Tonga", "bordersShapeUrl": "../Tonga.png" },
  { "name": "Comoros", "bordersShapeUrl": "../Comoros.png" },
  { "name": "Brunei", "bordersShapeUrl": "../Brunei.png" },
  { "name": "Chad", "bordersShapeUrl": "../Chad.png" },
  { "name": "Ghana", "bordersShapeUrl": "../Ghana.png" },
  { "name": "Kiribati", "bordersShapeUrl": "../Kiribati.png" },
  { "name": "Portugal", "bordersShapeUrl": "../Portugal.png" },
  { "name": "Mongolia", "bordersShapeUrl": "../Mongolia.png" },
  { "name": "Zimbabwe", "bordersShapeUrl": "../Zimbabwe.png" },
  { "name": "Israel", "bordersShapeUrl": "../Israel.png" },
  { "name": "Belgium", "bordersShapeUrl": "../Belgium.png" },
  { "name": "Republic of the Congo", "bordersShapeUrl": "../Republic of the Congo.png" },
  { "name": "Nicaragua", "bordersShapeUrl": "../Nicaragua.png" },
  { "name": "New Zealand", "bordersShapeUrl": "../New Zealand.png" },
  { "name": "Kosovo", "bordersShapeUrl": "../Kosovo.png" }
]

const directions = ['top', 'top-left', 'top-right', 'left', 'right', 'bottom', 'bottom-left', 'bottom-right'];

const Shapele = () => {
  const [currentCountry, setCurrentCountry] = useState(null);
  const [options, setOptions] = useState([]);
  const [revealPercentage, setRevealPercentage] = useState(0);
  const [direction, setDirection] = useState('');
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [timer, setTimer] = useState(10);
  const [selectedOption, setSelectedOption] = useState(null);

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    // Retrieve the high score from cookies on mount
    const storedHighScore = getCookie('highScore');
    if (storedHighScore) {
      setHighScore(parseInt(storedHighScore, 10));
    }
  }, []);

  const startNewRound = useCallback(() => {
    const shuffled = [...mockCountries].sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 4);
    const correctAnswer = selected[Math.floor(Math.random() * selected.length)];

    setCurrentCountry(correctAnswer);
    setOptions(selected);
    setDirection(directions[Math.floor(Math.random() * directions.length)]);
    setRevealPercentage(0);
    setTimer(15);
    setSelectedOption(null);
  }, []);



  useEffect(() => {
    startNewRound();
  }, [startNewRound]);

  useEffect(() => {
    if (timer > 0 && !gameOver) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 0.15); // decrement by 0.2 seconds
        setRevealPercentage(prev => prev + (100 / 5 / 20)); // 100% / 15 seconds / 5 intervals per second
      }, 50); // interval of 0.2 seconds
      setIntervalId(interval);
      return () => clearInterval(interval);
    } else if (timer <= 0 && !gameOver) {
      setGameOver(true);
    }
  }, [timer, gameOver]);
  
const handleOptionClick = (option) => {
    setSelectedOption(option);
    if (option.name === currentCountry.name) {
      const pointsEarned = Math.floor(100 - revealPercentage);
      if (pointsEarned<0) pointsEarned = 0;
      const newScore = score + pointsEarned;
      setScore(newScore);

      // Update high score if the new score is greater
      if (newScore > highScore) {
        setHighScore(newScore);
        setCookie('highScore', newScore, 365); // Store high score in cookies for 1 year
      }
      clearInterval(intervalId);
      setTimeout(() => {
        startNewRound();
        setRevealPercentage(0); // Reset reveal percentage immediately
      }, 1000);
    } else {
      setGameOver(true);
       clearInterval(intervalId);
    }
  };

  const restartGame = () => {
    setScore(0);
    setGameOver(false);
    startNewRound();
    setRevealPercentage(0); // Reset reveal percentage immediately
  };


  const [faq, setFaq] = useState([
    {
      question: "What is Shapele?",
      answer: "Shapele is a geography-based guessing game where you identify countries based on their shapes. The game reveals the shape of a country incrementally, and you need to guess the country before the shape is fully revealed. The quicker you guess correctly, the higher your score.",
      open: false
    },
    {
      question: "How do I play?",
      answer: "To play Shapele, start the game and observe the shape of the country as it is gradually revealed. You will have multiple choice options to select from. Click on the option that you believe matches the country shape. The faster you guess correctly, the higher your score will be. If you guess incorrectly or time runs out, the game will be over.",
      open: false
    },
    {
      question: "How is the score calculated?",
      answer: "The score is calculated based on the percentage of the country shape revealed when you make your guess. The less of the shape revealed, the higher the score you will receive. For example, if you guess the country with only 20% of the shape revealed, you will receive more points compared to guessing when 80% of the shape is revealed.",
      open: false
    },
    {
      question: "Can I save my progress?",
      answer: "Currently, Shapele does not support saving progress within ongoing games. However, your high score is saved in your browser cookies. This means that you can close the game and come back later, and your highest score will still be available as long as you haven't cleared your browser cookies.",
      open: false
    },
    {
      question: "What happens when the timer runs out?",
      answer: "If the timer runs out before you make a guess, the game will be over. Your score for that round will be calculated based on the percentage of the country shape revealed. You can then choose to restart the game and try again to beat your high score.",
      open: false
    },
    {
      question: "How can I improve my score?",
      answer: "To improve your score in Shapele, focus on recognizing country shapes quickly and accurately. Familiarize yourself with the shapes of different countries by practicing and playing multiple rounds. The faster you can correctly identify the country shape, the higher your score will be. Additionally, aim to make your guess with less of the shape revealed to earn more points.",
      open: false
    },
    {
      question: "Is Shapele available on mobile devices?",
      answer: "Yes, Shapele is designed to be responsive and can be played on mobile devices as well as desktop computers. The gameplay experience is optimized for both platforms, allowing you to enjoy the game on the go or at home.",
      open: false
    },
    {
      question: "Who can play Shapele?",
      answer: "Shapele is suitable for players of all ages who have an interest in geography and country shapes. It can be a fun and educational game for students, geography enthusiasts, and anyone looking to test their knowledge of world countries. The game is easy to pick up and play, making it accessible to a wide audience.",
      open: false
    }
  ]);
  

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, open: !item.open } : item)));
  };

  if (!currentCountry) return <div>Loading...</div>;

  return (
    <>
    <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-5 pb-10 h-[950px] sm:h-[765px] bg-white shadow-lg sm:rounded-3xl sm:px-20">
       
    <Card className="w-full max-w-2xl mx-auto sm:w-96 w-96">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center sm:text-xl sm:py-2">
        <img 
            src='../sjal.png' 
            alt="Logo" 
          />
        </CardTitle>
      </CardHeader>
      <CardContent>
      <div className="mb-4 text-lg font-semibold text-center flex justify-between">
          <span>Score: {score}</span>
          <span>High Score: {highScore}</span>
        </div>
        <div
            className=" top-0 left-0 h-4 bg-gradient-to-r from-green-200 to-green-600 transition-all duration-200"
            style={{ width: `${100 - revealPercentage}%`, height: '20px' }}
          ></div>
          
          <div className="relative mb-2 w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl bg-gray-200">
          
        </div>
        <div className="relative w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl bg-gray-200 overflow-hidden mx-auto  inset-0 bg-cover bg-center" style={{ backgroundImage: 'url("../bgimg.jpg")', aspectRatio: '1' }}>
          
          <img 
            src={currentCountry.bordersShapeUrl} 
            alt="Country Shape" 
            className="absolute inset-0 w-full h-full object-contain z-0"
            style={{ 
              background: 'rgba(255,255,255,1)',
              clipPath: `inset(${direction.includes('top') ? `${100 - revealPercentage}% ` : '0 '}${direction.includes('right') ? `${100 - revealPercentage}% ` : '0 '}${direction.includes('bottom') ? `${100 - revealPercentage}% ` : '0 '}${direction.includes('left') ? `${100 - revealPercentage}%` : '0'})`,
              zIndex: 2
            }}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-2 gap-4 mt-4">
          {options.map((option) => (
            <Button
              key={option.name}
              onClick={() => handleOptionClick(option)}
              className={`p-2 ${
                selectedOption
                  ? option.name === currentCountry.name
                    ? 'bg-green-500 hover:bg-green-600'
                    : option.name === selectedOption.name
                    ? 'bg-red-500 hover:bg-red-600'
                    : 'bg-gray-300 hover:bg-gray-400'
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white font-bold py-2 px-4 rounded transition-colors`}
              disabled={gameOver || selectedOption !== null}
            >
              {option.name}
            </Button>
          ))}
        </div>
        {gameOver && (
          <div className="mt-4 text-center">
            <p className="text-xl font-bold">Game Over! Your score: {score}</p>
            <Button onClick={restartGame} className="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors">Restart Game</Button>
          </div>
        )}
      </CardContent>
    </Card>

      </div>
   </div>
     <div className="flex items-center justify-center ">
     <a    style={{ marginTop: '30px', marginBottom: "30px"}}  href="https://www.buymeacoffee.com/shapele" target="_blank" rel="noopener noreferrer">
       <img 
         src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" 
         alt="Buy Me A Coffee" 
         style={{ height: '60px', width: '217px' }} 
       />
     </a>
   </div>
   <div className="mt-8 shadow rounded-lg px-14">
        <h2 className="text-2xl font-bold text-center mb-4">Frequently Asked Questions</h2>
        {faq.map((item, index) => (
          <div key={index} className="mb-4">
            <button
              onClick={() => toggleFaq(index)}
              className="w-full text-left text-lg font-semibold p-2 bg-gray-200 hover:bg-gray-300 rounded-lg focus:outline-none focus:bg-gray-300"
            >
              {item.question}
            </button>
            {item.open && (
              <div className="mt-2 p-2 bg-gray-100 rounded-lg">
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
     
   </>
  );
};

export default Shapele;
