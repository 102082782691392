import React from 'react';
import GeoShapele from './GeoShapele';

const App = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12" 
    ref={element => { 
      if (element) element.style.setProperty('padding-bottom', '0px', 'important'); 
    }}>
       <GeoShapele /> 
      </div>
  );
};

export default App;
