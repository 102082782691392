import React from 'react';

const Card = ({ children, className }) => (
  <div className={`w-full max-w-2xl mx-auto ${className}`}>
    {children}
  </div>
);

const CardContent = ({ children }) => (
  <div className="p-4">
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="p-4 border-b">
    {children}
  </div>
);

const CardTitle = ({ children }) => (
  <h2 className="text-2xl font-bold text-center">
    {children}
  </h2>
);

export { Card, CardContent, CardHeader, CardTitle };
